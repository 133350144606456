import { Button, Divider, InputLabel, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ModalComponent from "../../../common/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "./AddClient.scss";
import { clientRequests } from "../../../common/apiStructure/requests";
import { instance } from "../../../common/apiStructure/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  errorToaster,
  successToaster,
} from "../../../../utils/CommonFunctions";
import { HARD_RELOAD } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useWindowSize } from "react-use";
import { Box } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useStyles } from "../../../common/Modal/ModalStyles";

const getCountries = (t) => {
  return [
    { name: t("Sweden"), value: "sweden" },
    { name: t("Finland"), value: "finland" },
    { name: t("Norway"), value: "norway" },
    { name: t("Denmark"), value: "denmark" },
    { name: t("Switzerland"), value: "switzerland" },
    { name: t("Germany"), value: "germany" },
    { name: t("Spain"), value: "spain" },
    { name: t("France"), value: "france" },
    { name: t("England"), value: "england" },
    { name: t("United States"), value: "united states" },
  ];
};

export const AddClient = ({ modalOpen, handleCloseModal, param }) => {
  const { t } = useTranslation();
  const modalProps = { width: 400, mobileWidth: 240 };
  const classes = useStyles(modalProps);
  const countries = getCountries(t);
  const windowSize = useWindowSize();
  const margin = windowSize.width >= 768 ? "normal" : "dense";
  // const classes = useStyles();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phonenumber: "",
    streetAddress: "",
    zipCode: "",
    city: "",
    country: "",
  });

  const auth = useSelector((state) => state.auth);
  const hardReload = useSelector((state) => state.hardReload);
  const editClientIdx = useSelector((state) => state.editClientIdx);
  const allClients = useSelector((state) => state.allClients);
  const dispatch = useDispatch();

  useEffect(() => {
    if (param) {
      let clientData = allClients.filter(
        (client) => client._id === editClientIdx
      );
      setValues({
        ...values,
        firstName: clientData[0].firstname,
        lastName: clientData[0].lastname,
        email: clientData[0].email,
        streetAddress: clientData[0].streetaddress,
        zipCode: clientData[0].zipcode,
        city: clientData[0].city,
        country: clientData[0].country,
        phonenumber: clientData[0].phonenumber,
        _id: clientData[0]._id,
      });
    }
  }, []);

  const isDisable = Boolean(
    values.firstName && values.lastName && values.email
  );

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const addNewClient = async () => {
    try {
      let requestBody = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        phonenumber: values.phonenumber,
        streetaddress: values.streetAddress,
        zipcode: values.zipCode,
        city: values.city,
        country: values.country,
        therapistId: auth.user._id,
      };
      if (param) {
        const response = await instance.post(
          `${clientRequests.updateClient}/${values._id}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response) {
          successToaster(t("Client data has been updated!"));
        }
      } else {
        const response = await instance.post(
          clientRequests.addQuickClient,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response) {
          successToaster(t("New Client Added!"));
        }
      }
      dispatch(HARD_RELOAD(!hardReload));
      setValues({
        firstName: "",
        lastName: "",
        email: "",
        phonenumber: "",
        streetAddress: "",
        zipCode: "",
        city: "",
        country: "",
        _id: "",
      });
      handleCloseModal();
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  console.log("values",values)

  return (
    <ModalComponent modalOpen={modalOpen} handleCloseModal={handleCloseModal}>
      <div
        className={
          windowSize.width >= 768 ? classes.paper : classes.mobilePaper
        }
      >
        <div className="cardHeader">
          <h2 className="marginBottom">
            {param ? t("Update Client") : t("Add New Client")}
          </h2>
          <CloseIcon onClick={handleCloseModal} />
        </div>
        <Divider />

        <form className="formMarginTop" autoComplete="off">
          <TextField
            required
            margin={margin}
            label={t("Firstname")}
            name="firstName"
            value={values.firstName}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            margin={margin}
            label={t("Lastname")}
            name="lastName"
            value={values.lastName}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            margin={margin}
            label={t("Emailaddress")}
            name="email"
            value={values.email}
            type="email"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            margin={margin}
            label={t("Street address")}
            name="streetAddress"
            value={values.streetAddress}
            onChange={handleInputChange}
            variant="outlined"
            className="street_input"
            fullWidth
          />
          <TextField
            margin={margin}
            label={t("ZIP code")}
            name="zipCode"
            value={values.zipCode}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            margin={margin}
            label={t("City")}
            name="city"
            value={values.city}
            onChange={handleInputChange}
            variant="outlined"
            className="street_input"
            fullWidth
          />
          <FormControl
            margin={margin}
            variant="outlined"
            className="country_input"
            fullWidth
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {t("Country")}
            </InputLabel>
            <Select
              value={values.country}
              onChange={(e) =>
                setValues({ ...values, country: e.target.value })
              }
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Country * "
            >
              <MenuItem selected={values.country === ""} value="">
                <em>None</em>
              </MenuItem>
              {countries.map((country) => {
                return (
                  <MenuItem value={country.value}>{country.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <TextField
            margin={margin}
            label={t("Phonenumber")}
            value={values.contactNumber}
            name="contactNumber"
            onChange={handleInputChange}
            variant="outlined"
            type="text"
            fullWidth
          /> */}
          <Box width={"100%"} my={1} mt={1}>
            <PhoneInput
              inputStyle={{ width: "100%" ,height:"40px"}}
              country={"se"}
              name="phonenumber"
              placeholder={t("Phonenumber")}
              value={values.phonenumber}
              onChange={(value) =>
                setValues({
                  ...values,
                  phonenumber: value,
                })
              }
            />
          </Box>
          <div className="header_buttons">
            <Button
              disabled={!isDisable}
              onClick={addNewClient}
              variant="contained"
              fullWidth
            >
              {param ? t("Update") : t("Add")}
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};
