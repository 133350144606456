import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./successDialogBox.scss";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

function SuccessDialogBox({
  open,
  children,
  handleClose,
  handleOK,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"

      >
        <div className="backgroung_set">
          <DialogContent>
            {/* <CheckCircleOutlineRoundedIcon className="success_icon" />
            <br/>
            {"Program Sent Successfully!"}
            <div className="ok_button">
              <Button
                onClick={handleOK}
                variant="contained"
              >
                {t("Ok")}
              </Button>
            </div> */}
            {children}
          </DialogContent>
          {/* <DialogActions>
            
          </DialogActions> */}
        </div>
      </Dialog>
    </>
  );
}

export default SuccessDialogBox;
