import axios from "axios";
import { errorToaster } from "../../../utils/CommonFunctions";

const sendbox = "https://sandboxapi.optimum-method.com/api";
const live = "https://api.optimum-method.com/api";

const instance = axios.create({
  baseURL: live,
});

//Query Client Data
let tokenSource;

const searchFn = async (
  keyword,
  token,
  therapistId,
  clientId,
  selfProgramParam,
  createExerciseButton
) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel("Operation canceled due to new request.");
    }

    //save new request for cancellation
    tokenSource = axios.CancelToken.source();

    // if (clientSearch) {
    //   const { data } = await axios.get(
    //     `https://api.optimum-method.com/api/client/searchclient/${therapistId}?search=${keyword}`,
    //     {
    //       cancelToken: tokenSource.token,
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   return { result: data };
    // } else
    if (createExerciseButton) {
      let requestBody = {
        therapistId: therapistId,
        clientId: clientId,
        adminId: "5fe46e36bde6076a09c2c6ff",
      };
      const { data } = await axios.post(
        `${live}/exercise/listOfExercise?search=${keyword}`,
        requestBody,
        {
          cancelToken: tokenSource.token,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { result: data };
    } else {
      let requestBody = {
        therapistId: therapistId,
        clientId: selfProgramParam ? null : clientId,
      };
      const { data } = await axios.post(
        `${live}/program/searchProgram?search=${keyword}`,
        requestBody,
        {
          cancelToken: tokenSource.token,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { result: data };
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }
    if (err.response && err.response.data) {
      errorToaster(err.response.data.error.message);
    }
    return [err];
  }
};

export {
  instance,
  // searchClient,
  searchFn,
};
