import React, { useEffect, useState } from "react";
import "./App.scss";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

function App() {
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth?.token) {
      const script = document.createElement("script");
      script.src = "https://bot.optimum-method.com/chat.min.js";
      script.setAttribute(
        "data-chat-url",
        "https://bot.optimum-method.com/bot/d8f465a4-c96e-4b1f-b1a8-6562bf676393"
      );
      script.setAttribute("data-btn-position", "bottom-right");
      script.setAttribute("data-widget-btn-color", "#1e90ff");
      script.defer = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [auth]);
  return (
    //USE BEM CONVENTIONS FOR CLASS-NAMES
    <div className="app">
      <Routes />
      <Toaster
        toastOptions={{
          duration: 2500,
          position: "top-right",
          success: {
            style: {
              border: "2px solid lightgreen",
              color: "white",
              background: "linear-gradient(#00486c, #00a0de)",
              letterSpacing: 0.5,
              fontWeight: "bold",
            },
          },
          error: {
            style: {
              border: "2px solid red",
              color: "white",
              background: "linear-gradient(#00486c, #00a0de)",
              letterSpacing: 0.5,
              fontWeight: "bold",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
