export function SELECT_LANGUAGE(res) {
  return { type: "SELECT_LANGUAGE", res };
}

export function SET_EMAIL(res) {
  return { type: "SET_EMAIL", res };
}

export function SET_PLAN_ID(res) {
  return { type: "SET_PLAN_ID", res };
}

export function SET_OTP(res) {
  return { type: "SET_OTP", res };
}

export function SET_SUBSCRIBED_STATUS(res) {
  return { type: "SET_SUBSCRIBED_STATUS", res };
}
export function USER_DATA(res) {
  return { type: "USER_DATA", res };
}

export function SET_SUBSCRIPTION_ID(res) {
  return { type: "SET_SUBSCRIPTION_ID", res };
}

export function SET_AUTH(res) {
  return { type: "SET_AUTH", res };
}

export function HARD_RELOAD(res) {
  return { type: "HARD_RELOAD", res };
}

export function SET_CLIENT_DATA(res) {
  return { type: "SET_CLIENT_DATA", res };
}

export function EDIT_CLIENT_IDX(res) {
  return { type: "EDIT_CLIENT_IDX", res };
}

export function SET_EDIT_EXERCISE_DATA(res) {
  return { type: "SET_EDIT_EXERCISE_DATA", res };
}

export function SET_PARTICULAR_CLIENT(res) {
  return { type: "SET_PARTICULAR_CLIENT", res };
}

export function LOG_OUT() {
  return { type: "LOG_OUT" };
}

export function SET_PARTICULAR_PROGRAM(res) {
  return { type: "SET_PARTICULAR_PROGRAM", res };
}

export function EXERCISE_DESCRIPTION(res) {
  return { type: "EXERCISE_DESCRIPTION", res };
}

export function TAB_INDEX_FOR_PDF_LINK(res) {
  return { type: "TAB_INDEX_FOR_PDF_LINK", res };
}

export function STORE_JSON_DATA(res) {
  return { type: "STORE_JSON_DATA", res };
}

export function TEMP_USER_DATA(res) {
  return { type: "TEMP_USER_DATA", res };
}

export function SET_DRAFT_MESSAGE(res) {
  return { type: "SET_DRAFT_MESSAGE", res };
}

export function SET_DRAFT_RECIPIENTS(res) {
  return { type: "SET_DRAFT_RECIPIENTS", res };
}

export function SET_SEARCH_INPUT_VALUE(res) {
  return { type: "SET_SEARCH_INPUT_VALUE", res };
}

export function SET_SEARCH_ACTIVE_STATUS(res) {
  return { type: "SET_SEARCH_ACTIVE_STATUS", res };
}

export function SET_SEARCH_FOCUS(res) {
  return { type: "SET_SEARCH_FOCUS", res };
}

export function SET_ALL_EXERCISE(res) {
  return { type: "SET_ALL_EXERCISE", res };
}

export function SET_CURRENT_VIRSION(res) {
  return { type: "SET_CURRENT_VIRSION", res };
}

export function SET_CURRENT_VIRSION_INDEX_FOR_ONLINE_PROGRAM(res) {
  return { type: "SET_CURRENT_VIRSION_INDEX_FOR_ONLINE_PROGRAM", res };
}

export function SET_SORT_STATUS(res) {
  return { type: "SET_SORT_STATUS", res };
}

export function SET_BACK_STATUS_FROM_EXE_DESC(res) {
  return { type: "SET_BACK_STATUS_FROM_EXE_DESC", res };
}

export function TEMP_ALL_CHECKED_EXE(res) {
  return { type: "TEMP_ALL_CHECKED_EXE", res };
}

export function TEMP_CLIENT_DATA(res) {
  return { type: "TEMP_CLIENT_DATA", res };
}

export function SET_PARTICULAR_CLIENT_DATA(res) {
  return { type: "SET_PARTICULAR_CLIENT_DATA", res };
}

export function SET_EXERCISE_LIST_DATA(res) {
  return { type: "SET_EXERCISE_LIST_DATA", res };
}

export function SELECTED_EXERCISE_LIST(res) {
  return { type: "SELECTED_EXERCISE_LIST", res };
}

export function SHOW_ACTION_BUTTON(res) {
  return { type: "SHOW_ACTION_BUTTON", res };
}

export function SET_DAILY_EXE(res) {
  return { type: "SET_DAILY_EXE", res };
}

export function SET_SCROLL(res) {
  return { type: "SET_SCROLL", res };
}

export function SET_SELECTED_SCROLL(res) {
  return { type: "SET_SELECTED_SCROLL", res };
}

export function SET_CLIENT_LINK(res) {
  return { type: "SET_CLIENT_LINK", res };
}

export function SELECT_OOPLAN(res) {
  return { type: "SELECT_OOPLAN", res };
}

export function RE_LOGIN(res) {
  return { type: "RE_LOGIN", res };
}
export function VISIT_SEND_FROM_ANOTHER_DEVICE(res) {
  return { type: "VISIT_SEND_FROM_ANOTHER_DEVICE", res };
}
export function NEW_VISIT_CREATED(res) {
  return { type: "NEW_VISIT_CREATED", res };
}
export function ACTIVE_EXERCISE_AND_TREATMEN_TAB(res) {
  return { type: "ACTIVE_EXERCISE_AND_TREATMEN_TAB", res };
}
export function SET_BUSERTOKEN(res) {
  return { type: "SET_BUSERTOKEN", res };
}
export function SET_PARTICULAR_BUSER_PROGRAM(res) {
  return { type: "SET_PARTICULAR_BUSER_PROGRAM", res };
}
export function EMPTY_PARTICULAR_BUSER_PROGRAM() {
  return { type: "EMPTY_PARTICULAR_BUSER_PROGRAM" };
}
export function SET_IDS_BUSER_PROGRAM(res) {
  return { type: "SET_IDS_BUSER_PROGRAM", res };
}
export function SET_BUSER_PROGRAM(res) {
  return { type: "SET_BUSER_PROGRAM", res };
}
