import * as React from "react"

function HomeIconActive({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 535.32 441.427"
    >
      <g fill="#499fdd">
        <path d="M503.783 85.22L32.214 84.043C14.858 83.999.832 65.148.89 41.94.948 18.735 15.068-.045 32.424-.003l471.57 1.179c17.355.044 31.38 18.893 31.323 42.1-.058 23.207-14.178 41.988-31.534 41.944M503.339 263.324l-471.57-1.179C14.413 262.101.387 243.251.445 220.044c.058-23.207 14.178-41.987 31.534-41.944l471.569 1.179c17.356.044 31.383 18.893 31.324 42.101-.057 23.205-14.177 41.987-31.533 41.944M502.893 441.427l-471.569-1.179C13.968 440.204-.058 421.353.001 398.146c.057-23.206 14.177-41.986 31.533-41.943l471.569 1.178c17.356.044 31.382 18.894 31.324 42.102-.058 23.206-14.178 41.986-31.534 41.944" />
      </g>
    </svg>
  )
}

export default HomeIconActive
