import React, { useState, useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import "./AppHeader.scss";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ImArrowLeft } from "react-icons/im";
import _ from "lodash";
import { FaCrown } from "react-icons/fa";
import shortId from "short-uuid";
import { searchFn } from "../apiStructure/axios";
import { VscArchive } from "react-icons/vsc";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  SET_CLIENT_DATA,
  SET_SORT_STATUS,
  SET_SEARCH_INPUT_VALUE,
  SET_PARTICULAR_PROGRAM,
  SET_EXERCISE_LIST_DATA,
  SHOW_ACTION_BUTTON,
  SET_DAILY_EXE,
  SET_SCROLL,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FontAwesome from "react-fontawesome";
import { Icon } from "semantic-ui-react";
import {
  exerciseRequests,
  clientRequests,
  programRequests,
} from "../../common/apiStructure/requests";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { instance } from "../../common/apiStructure/axios";
import {
  errorToaster,
  handleMoveExerciseForAll,
} from "../../../utils/CommonFunctions";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import CreateProgram from "../svg/CreateProgram";
import ProgramListIcon from "../svg/ProgramListIcon";
import ProgramListIconHover from "../svg/ProgramListIconHover";
import CreateProgramHover from "../svg/CreateProgramHover";
import AddExe from "../svg/AddExe";
import AddExeHover from "../svg/AddExeHover";
import ListOfExeHover from "../svg/ListOfExeHover";
import ListOfExe from "../svg/ListOfExe";
import ForwardExeHover from "../svg/ForwardExeHover";
import ForwardExc from "../svg/ForwardExc";
import TipsHover from "../svg/TipsHover";
import Tips from "../svg/Tips";
import { useHistory, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";

function AppHeader({
  currentRoute,
  getClients,
  title,
  placeholder,
  programListButton,
  backIcon,
  handleBack,
  createPrograms,
  createProgramButton,
  handleRedirect,
  setPrograms,
  selfProgramParam,
  clientId,
  createExercise,
  createExerciseButton,
  setExercise,
  searchExeList,
  AddTips,
  CreateDraft,
  tipsHeader,
  searchQueryNull,
  setSearchQueryNull,
  showFilterVersionButtons,
  versionButtons,
  filterByVersionFn,
  currentVersionIndex,
  type,
  searchQueryCheck,
  setSearchQueryCheck,
  AddExerciseInProgram,
  sendPdfToUser,
  updateSortDataandexe,
  setUpdateSortDataandexe,
  userQueryNull,
  setUserQueryNull,
  tempNameForProgram,
  setTempNameForProgram,
  largeTitle,
  nullAndNoExe,
  onlyExeList,
  searchingVal, // for only exercise list component
  searchActiveStatus,
  currentEditIndex,
  particularProgramData,
  currentVersion,
  setCurrentEditIndex,
  CallMoveExerciseFn,
}) {
  const { t } = useTranslation();
  const inputEl = useRef();
  const { width, height } = useWindowSize();
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const history = useHistory();
  const [sortedExercise, setSortedExercise] = useState([]);
  const [tempExe, setTempExe] = useState([]);
  const [tempUser, setTempUser] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const program = useSelector((state) => state.particularProgramData);
  const actionButton = useSelector((state) => state.actionSummaryButton);
  const visits = useSelector((state) => state.setParticularClient.Visits);
  const language = useSelector((state) => state.language);
  const searchVal = useSelector((state) => state.searchVal);
  const sortStatus = useSelector((state) => state.sortStatus);
  const client = useSelector((state) => state.particularClientData);
  const location = useLocation();
  const [addProgramHover, setAddProgramHover] = useState(false);
  const [addActionSummaryHover, setActionSummaryHover] = useState(false);
  const [programListHover, setProgramListHover] = useState(false);
  const [createExeHover, setCreateExeHover] = useState(false);
  const [forwardExeHover, setForwardExeHover] = useState(false);
  const [listOfExeHover, setListOfExeHover] = useState(false);
  const [tipsHover, setTipsHover] = useState(false);
  const [allDailyExeList, setAllDailyExeList] = useState([]);
  const [searchActive, setSearchActiveStatus] = useState(true);

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   let filtered;
  //   if (createExerciseButton) {
  //     if (value.length === 0) {
  //       setExercise([]);
  //       setSearchQueryCheck(false);
  //     } else if (value.length === 1) {
  //       setExercise(tempExe);
  //       setSearchQueryCheck(true);
  //     } else {
  //       setSearchQueryCheck(true);
  //       filtered = tempExe.list.filter((exe) => {
  //         return exe.abbreviation.toLowerCase() === value.toLowerCase();
  //       });
  //       if (filtered.length === 0) {
  //         filtered = tempExe.list.filter((exe) => {
  //           return (
  //             exe.name.toLowerCase().includes(value.toLowerCase()) ||
  //             exe.abbreviation.toLowerCase().includes(value.toLowerCase())
  //           );
  //         });
  //       }
  //       const body = {
  //         list: filtered,
  //         totalPage: tempExe.totalPage,
  //       };
  //       setExercise(body);
  //     }
  //   } else if (programListButton) {
  //     let clientFilter;
  //     if (value.length === 0) {
  //       dispatch(SET_CLIENT_DATA(tempUser));
  //     } else {
  //       clientFilter = tempUser.filter((user) => {
  //         return (
  //           user.firstname.toLowerCase().includes(value.toLowerCase()) ||
  //           user.lastname.toLowerCase().includes(value.toLowerCase())
  //         );
  //       });
  //       dispatch(SET_CLIENT_DATA(clientFilter));
  //     }
  //   } else {
  //     const search = _.debounce(sendQuery, 100);

  //     setSearchQuery((prevSearch) => {
  //       if (prevSearch.cancel) {
  //         prevSearch.cancel();
  //       }
  //       return search;
  //     });

  //     search(value);
  //   }
  //   setQuery(value);
  // };

  const handleChange = (e) => {
    const value = e.target.value;
    let filtered = [];
    if (createExerciseButton) {
      dispatch(SET_SEARCH_INPUT_VALUE(value));
      if (value.length === 0) {
        setExercise([]);
        setSearchQueryCheck(false);
        nullAndNoExe(true);
      } else if (value.length === 1) {
        setSearchQueryCheck(true);
        tempExe.list.map((exe) => {
          let exe_name = exe.name.toLowerCase();
          let exe_abbrivation = exe.abbreviation.toLowerCase();
          if (exe_name.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
          if (exe_abbrivation.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
        });

        tempExe.list.map((exe) => {
          filtered.push(exe);
        });

        let uniquefiltered = [...new Set(filtered)];

        const body = {
          list: _.uniqBy(uniquefiltered, "_id"),
          totalPage: tempExe.totalPage,
        };
        setExercise(body);
      } else {
        setSearchQueryCheck(true);

        tempExe.list.map((exe) => {
          let exe_name = exe.name.toLowerCase();
          let exe_abbrivation = exe.abbreviation.toLowerCase();
          if (exe_name.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
          if (exe_abbrivation.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
        });
        const body = {
          list: _.uniqBy(filtered, "_id"),
          totalPage: tempExe.totalPage,
        };
        setExercise(body);
      }
    } else if (programListButton) {
      let clientFilter;

      // if (value.length === 0) {
      //   dispatch(SET_CLIENT_DATA(tempUser));
      // } else if (value.length === 1) {
      //   // ClientSearch();
      //   let filterList = [];
      //   tempUser.map((client, i) => {
      //     let clientFname = client.firstname.toLowerCase();
      //     if (clientFname.startsWith(`${value.toLowerCase()}`)) {
      //       filterList.push(client);
      //     }
      //   });
      //   dispatch(SET_CLIENT_DATA(filterList));
      // } else if (value.length >= 2 && value[1] != " ") {
      //   let filterList = [];
      //   tempUser.map((client, i) => {
      //     let clientFname = client.firstname.toLowerCase();
      //     if (clientFname.startsWith(`${value.toLowerCase()}`)) {
      //       filterList.push(client);
      //     }
      //   });
      //   dispatch(SET_CLIENT_DATA(filterList));
      // } else if (value.length === 2 && value[1] === " ") {
      //   let filterList = [];
      //   tempUser.map((client, i) => {
      //     let clientFname = client.firstname.toLowerCase();
      //     if (clientFname.startsWith(`${value[0].toLowerCase()}`)) {
      //       filterList.push(client);
      //     }
      //   });
      //   dispatch(SET_CLIENT_DATA(filterList));
      // } else if (value.length > 2 && value[1] === " ") {
      //   let filterList = [];
      //   tempUser.map((client, i) => {
      //     let clientFname = client.firstname.toLowerCase();
      //     let clientLname = client.lastname.toLowerCase();
      //     if (
      //       clientFname.startsWith(`${value[0].toLowerCase()}`) &&
      //       clientLname.startsWith(`${value[2].toLowerCase()}`)
      //     ) {
      //       filterList.push(client);
      //     }
      //   });
      //   dispatch(SET_CLIENT_DATA(filterList));
      // }
      // getClients(value);
      if (value !== "" && value.length > 1) {
        getClients(value);
      } else {
        getClients();
      }
      // else {
      //   clientFilter = tempUser.filter((user) => {
      //     return (
      //       user.firstname.toLowerCase().includes(value.toLowerCase()) ||
      //       user.lastname.toLowerCase().includes(value.toLowerCase())
      //     );
      //   });
      //   dispatch(SET_CLIENT_DATA(clientFilter));
      // }
    } else if (onlyExeList) {
      searchingVal(value);
    } else {
      const search = _.debounce(sendQuery, 100);

      setSearchQuery((prevSearch) => {
        if (prevSearch.cancel) {
          prevSearch.cancel();
        }
        return search;
      });

      search(value);
    }
    setQuery(value);
  };

  useEffect(() => {
    if (searchQueryNull === true) {
      setQuery("");
      setExercise([]);
      setSearchQueryNull(false);
      inputEl.current.focus();
    }
  }, [searchQueryNull]);

  useEffect(() => {
    if (userQueryNull === true) {
      setQuery("");
      //ClientSearch();
      inputEl.current.focus();
      setUserQueryNull(false);
    }
  }, [userQueryNull]);

  useEffect(() => {
    if (type === "CreateDraft") {
      setQuery(program.programId);
    }
  }, [type]);

  useEffect(() => {
    if (updateSortDataandexe === true) {
      searchForClientsPExe();
      setUpdateSortDataandexe(false);
    }
  }, [updateSortDataandexe]);

  useEffect(() => {
    searchForClientsPExe();
    if (searchVal && createExerciseButton) {
      setQuery(searchVal);
      //searchedData();
    }
  }, []);

  useEffect(() => {
    if (createExerciseButton && tempExe.totalPage > 0 && searchVal != "") {
      searchedData();
    }
    if (searchVal === "") {
      setQuery("");
    }
  }, [tempExe]);

  const searchedData = () => {
    if (tempExe.totalPage > 0) {
      let filtered = [];

      setSearchQueryCheck(true);
      tempExe.list.map((exe) => {
        let exe_name = exe.name.toLowerCase();
        let exe_abbrivation = exe.abbreviation.toLowerCase();
        if (exe_name.startsWith(`${query.toLowerCase()}`)) {
          filtered.push(exe);
        }
        if (exe_abbrivation.startsWith(`${query.toLowerCase()}`)) {
          filtered.push(exe);
        }
      });
      const body = {
        list: _.uniqBy(filtered, "_id"),
        totalPage: tempExe.totalPage,
      };
      setExercise(body);
      console.log("setExercise   in");
    }
  };

  const creatingDailyExeList = async (data) => {
    const mainExerciseObject = {
      ...data,
      small: false,
      medium: false,
      daily: true,
      large: false,
      groupFirstKey: false,
      GroupedKey: false,
      groupFirstKeyForS: false,
      GroupedKeyForS: false,
      groupFirstKeyForM: false,
      GroupedKeyForM: false,
      groupFirstKeyForD: false,
      GroupedKeyForD: false,
      exe_uuid: shortId.generate(),
      comment: "",
      listType: "All Exercises List",
    };
    // let dailyList = [];
    setAllDailyExeList([allDailyExeList.push(mainExerciseObject)]);
    // dailyList.push(mainExerciseObject);
  };

  const searchForClientsPExe = async () => {
    try {
      let requestBody =
        auth.user.hasOwnProperty("level") && auth.user.level === 500
          ? {
              therapistId: auth.user._id,
              // clientId: clientId ? clientId : "",
              clientId: "",
              adminId: "5fe46e36bde6076a09c2c6ff",
              level: auth.user.hasOwnProperty("level") ? auth.user.level : 100,
            }
          : {
              therapistId: auth.user._id,
              level: auth.user.hasOwnProperty("level") ? auth.user.level : 100,
              // clientId: clientId ? clientId : "",
              clientId: "",
              adminId: "5fe46e36bde6076a09c2c6ff",
            };
      const response = await instance.post(
        `${exerciseRequests.fetchGlobalExercises}?language=${language}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      console.log("response.data.data ====> ", response.data.data);
      dispatch(SET_EXERCISE_LIST_DATA(response.data.data));
      response.data.data.list.map((data) => {
        creatingDailyExeList(data);
      });
      setTimeout(() => {
        dispatch(SET_DAILY_EXE(allDailyExeList));
      }, 1500);

      setSortedExercise(response.data.data);
      setTempExe(response.data.data);
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const ClientSearch = async (search) => {
    try {
      const response = await instance.get(
        `${clientRequests.searchClient}/${auth.user._id}/${search}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      dispatch(SET_CLIENT_DATA(response.data.data));
      setTempUser(response.data.data);
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  useEffect(() => {
    console.log("inputEl", inputEl.current);
    if (inputEl.current) {
      inputEl.current.focus();
      // ClientSearch();
    }
  }, []);

  useEffect(() => {
    if (userQueryNull === true) {
      setQuery("");
      // ClientSearch();
      //inputEl.current.focus();
      setUserQueryNull(false);
    }
  }, [userQueryNull]);

  const sendQuery = async (value) => {
    const { cancelPrevQuery, result } = await searchFn(
      value,
      auth.token,
      auth.user._id,
      clientId,
      programListButton,
      selfProgramParam,
      createExerciseButton
    );
    if (cancelPrevQuery) return;
    if (result) {
      // if (programListButton) {
      //   dispatch(SET_CLIENT_DATA(result.data));
      // } else
      if (createExerciseButton) {
        if (value.length > 0) {
          setExercise(result.data);
          setSortedExercise(result.data);
        } else {
          setExercise([]);
          setSortedExercise([]);
        }
      } else {
        setPrograms(result.data);
      }
    }
  };

  const sortExercises = () => {
    setSearchQueryCheck(true);
    sortedExercise?.list?.map((exe, i) => {
      exe.flow_number = parseInt(exe.flow_number);
    });
    if (sortStatus === 1) {
      //const sortedData = _.orderBy(sortedExercise.list, ["flow_number"], ["asc"]);
      const sortedData = _.orderBy(sortedExercise.list, ["name"], ["asc"]);
      const obj = {
        totalPage: sortedExercise.totalPage,
        list: sortedData,
      };
      setExercise(obj);
      dispatch(SET_SORT_STATUS(-1));
    } else {
      //const sortedData = _.orderBy(sortedExercise.list, ["name"], ["asc"]);
      const sortedData = _.orderBy(
        sortedExercise.list,
        ["flow_number"],
        ["asc"]
      );
      const obj = {
        totalPage: sortedExercise.totalPage,
        list: sortedData,
      };
      setExercise(obj);
      dispatch(SET_SORT_STATUS(1));
    }
  };

  const moveExerciseFn = (event, index, movement) => {
    event.stopPropagation();
    console.log("Event ==> ", event);
    console.log("Key Code ==> ", event.keyCode);
    console.log("movement ==> ", movement);

    if (event.keyCode === 38 || event.keyCode === 40 || movement) {
      const exerciseArrayForL = [...particularProgramData.list];
      const exerciseArrayForS = [...particularProgramData.small];
      const exerciseArrayForM = [...particularProgramData.medium];
      const exerciseArrayForD = [...particularProgramData.daily];

      const currentExerciseIndex = Number(
        event.currentTarget.parentElement.parentElement.id
      );
      console.log("currentExerciseIndex ==> ", currentExerciseIndex);
      const previousExerciseIndex =
        currentExerciseIndex < 1 ? 0 : currentExerciseIndex - 1;
      console.log("previousExerciseIndex ==> ", previousExerciseIndex);
      let nextExerciseIndex = null;
      if (currentVersion === "large") {
        nextExerciseIndex =
          currentExerciseIndex === exerciseArrayForL.length - 1
            ? currentExerciseIndex
            : currentExerciseIndex + 1;
        handleMoveExerciseForAll(
          exerciseArrayForL,
          movement,
          currentExerciseIndex,
          previousExerciseIndex,
          nextExerciseIndex,
          event
        );
      } else if (currentVersion === "small") {
        nextExerciseIndex =
          currentExerciseIndex === exerciseArrayForS.length - 1
            ? currentExerciseIndex
            : currentExerciseIndex + 1;
        handleMoveExerciseForAll(
          exerciseArrayForS,
          movement,
          currentExerciseIndex,
          previousExerciseIndex,
          nextExerciseIndex,
          event
        );
      } else if (currentVersion === "medium") {
        nextExerciseIndex =
          currentExerciseIndex === exerciseArrayForM.length - 1
            ? currentExerciseIndex
            : currentExerciseIndex + 1;
        handleMoveExerciseForAll(
          exerciseArrayForM,
          movement,
          currentExerciseIndex,
          previousExerciseIndex,
          nextExerciseIndex,
          event
        );
      } else if (currentVersion === "daily") {
        nextExerciseIndex =
          currentExerciseIndex === exerciseArrayForD.length - 1
            ? currentExerciseIndex
            : currentExerciseIndex + 1;
        handleMoveExerciseForAll(
          exerciseArrayForD,
          movement,
          currentExerciseIndex,
          previousExerciseIndex,
          nextExerciseIndex,
          event
        );
      }

      setCurrentEditIndex(
        movement === "moveUp" || event.keyCode === 38
          ? previousExerciseIndex
          : nextExerciseIndex
      );
      updateExerciseFn(
        exerciseArrayForL,
        exerciseArrayForS,
        exerciseArrayForM,
        exerciseArrayForD
      );
      dispatch(
        SET_PARTICULAR_PROGRAM({
          ...particularProgramData,
          list: exerciseArrayForL,
          small: exerciseArrayForS,
          medium: exerciseArrayForM,
          daily: exerciseArrayForD,
        })
      );
    }

    searchActiveStatus(false);
    let searchBox = document.getElementById("search-box-input");
    if (searchBox) {
      searchBox.focus();
    }
  };

  const updateExerciseFn = async (
    exerciseArray,
    newArrayForS,
    newArrayForM,
    newArrayForD
  ) => {
    let ListExerciseArray = exerciseArray;
    let SmallExerciseArray = [];
    let MediumExerciseArray = [];

    ListExerciseArray.map((exercise, index) => {
      let found_exe_for_m = newArrayForM.find(
        (exe) => exe.exe_uuid === exercise.exe_uuid
      );
      if (found_exe_for_m) {
        MediumExerciseArray.push(found_exe_for_m);
      }
      let found_exe_for_s = newArrayForS.find(
        (exe) => exe.exe_uuid === exercise.exe_uuid
      );
      if (found_exe_for_s) {
        SmallExerciseArray.push(found_exe_for_s);
      }
    });

    console.log("particularProgramData.medium =====> ", particularProgramData);
    const requestBody = {
      programId: particularProgramData.programId,
      tempProgramId: particularProgramData.tempProgramId,
      clientId: particularProgramData.clientId,
      therapistId: particularProgramData.therapistId,
      list: exerciseArray,
      small: SmallExerciseArray,
      medium: MediumExerciseArray,
      daily: newArrayForD ? newArrayForD : particularProgramData.daily,
      isPro: particularProgramData?.isPro ?? false,
    };

    //console.log("requestBody ===> ", requestBody);
    // const requestBody = {
    //   programId: particularProgramData.programId,
    //   tempProgramId: particularProgramData.tempProgramId,
    //   clientId: particularProgramData.clientId,
    //   therapistId: particularProgramData.therapistId,
    //   list: exerciseArray,
    //   small: newArrayForS ? newArrayForS : particularProgramData.small,
    //   medium: newArrayForM ? newArrayForM : particularProgramData.medium,
    //   daily: newArrayForD ? newArrayForD : particularProgramData.daily,
    // };
    try {
      const response = await instance.post(
        `${programRequests.updatePrograms}${particularProgramData._id}`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      if (response) {
        dispatch(SET_PARTICULAR_PROGRAM(response.data.data));
      }
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const handleKeyDown = (event) => {
    console.log("event.key", event.key);
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      //let FocusOnDiv = document.getElementById(`${currentEditIndex}`);
      //if(FocusOnDiv){
      //FocusOnDiv.focus();
      if (currentEditIndex) {
        CallMoveExerciseFn(event);
      }
      //}
    }
    if (createExerciseButton) {
      if (searchExeList && searchExeList.length && searchExeList.length === 1) {
        if (event.key === "Enter") {
          AddExerciseInProgram(searchExeList[0], event);
        }
      } else if (searchExeList && searchExeList.length) {
        if (event.key === "Enter") {
          const matchExe = searchExeList.filter(
            (exe) => exe.abbreviation === query.toLowerCase()
          );
          console.log("matchExe ==> ", matchExe);
          if (matchExe.length === 1) {
            AddExerciseInProgram(matchExe[0], event);
          }
        }
      }
    }
  };
  const handleHoverCreateProgram = () => {
    setAddProgramHover(true);
  };

  const handleHoverOutCreateProgram = () => {
    setAddProgramHover(false);
  };

  const handleHoverActionSummary = () => {
    setActionSummaryHover(true);
  };

  const handleHoverOutActionSummary = () => {
    setActionSummaryHover(false);
  };

  const handleHoverProgramList = () => {
    setProgramListHover(true);
  };

  const handleHoverOutProgramList = () => {
    setProgramListHover(false);
  };

  const handleHoverCreateExe = () => {
    setCreateExeHover(true);
  };

  const handleHoverOutCreateExe = () => {
    setCreateExeHover(false);
  };

  const handleHoverForwardExe = () => {
    setForwardExeHover(true);
  };

  const handleHoverOutForwardExe = () => {
    setForwardExeHover(false);
  };

  const handleHoverListOfExe = () => {
    setListOfExeHover(true);
  };

  const handleHoverOutListOfExe = () => {
    setListOfExeHover(false);
  };

  const handleHoverTips = () => {
    setTipsHover(true);
  };

  const handleHoverOutTips = () => {
    setTipsHover(false);
  };

  const redirectToBack = (e) => {
    e.stopPropagation();
    if (searchQueryCheck === true) {
      setSearchQueryCheck(false);
    } else {
      handleBack();
    }
  };

  const AddExerciseInProgramUsingCheckbox = async (data) => {
    const mainExerciseObject = {
      ...data,
      small: currentVersion === "small" ? true : false,
      medium: currentVersion === "medium" ? true : false,
      daily: currentVersion === "daily" ? true : false,
      large: currentVersion === "daily" ? false : true,
      groupFirstKey: false,
      GroupedKey: false,
      groupFirstKeyForS: false,
      GroupedKeyForS: false,
      groupFirstKeyForM: false,
      GroupedKeyForM: false,
      groupFirstKeyForD: false,
      GroupedKeyForD: false,
      exe_uuid: shortId.generate(),
      comment: "",
    };

    let requestBody = [];

    let listExe = particularProgramData.list;
    let mediumExe = particularProgramData.medium;
    let smallExe = particularProgramData.small;
    let dailyExe = particularProgramData.daily;

    if (currentVersion !== "daily") {
      let exeCheck = listExe.filter((e) => e.name === mainExerciseObject.name);
      if (exeCheck.length === 0) {
        listExe.push(mainExerciseObject);
      } else {
        errorToaster(mainExerciseObject.name + " is already exist.");
      }
    }
    if (currentVersion === "medium") {
      mediumExe.push(mainExerciseObject);
    }
    if (currentVersion === "small") {
      smallExe.push(mainExerciseObject);
    }
    if (currentVersion === "daily") {
      dailyExe.push(mainExerciseObject);
    }

    requestBody = {
      programId: particularProgramData.programId,
      tempProgramId: particularProgramData.tempProgramId,
      clientId: particularProgramData.clientId,
      therapistId: particularProgramData.therapistId,
      list:
        currentVersion !== "daily"
          ? particularProgramData.hasOwnProperty("list") === true && listExe
          : [...particularProgramData.list],
      medium:
        particularProgramData.hasOwnProperty("medium") === true
          ? currentVersion === "medium"
            ? mediumExe
            : [...particularProgramData.medium]
          : [],
      small:
        particularProgramData.hasOwnProperty("small") === true
          ? currentVersion === "small"
            ? smallExe
            : [...particularProgramData.small]
          : [],
      daily:
        particularProgramData.hasOwnProperty("daily") === true
          ? currentVersion === "daily"
            ? dailyExe
            : [...particularProgramData.daily]
          : [],
      isPro: particularProgramData?.isPro ?? false,
    };

    const saveObjinRedux = { ...requestBody, _id: particularProgramData._id };
    dispatch(SET_PARTICULAR_PROGRAM(saveObjinRedux));
    setSearchQueryCheck(false);
    setSearchQueryNull(true);
    dispatch(SET_SORT_STATUS(-1));

    try {
      const response = await instance.post(
        `${programRequests.updatePrograms}${particularProgramData._id}`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }

    if (currentEditIndex !== null) {
      // handleEditExercise(mainExerciseObject, currentEditIndex + 1);
    } else {
      let keyForData = "";
      if (currentVersion === "small") {
        keyForData = "small";
      } else if (currentVersion === "medium") {
        keyForData = "medium";
      } else if (currentVersion === "daily") {
        keyForData = "daily";
      } else {
        keyForData = "list";
      }
      let editIndex =
        particularProgramData.hasOwnProperty(keyForData) &&
        particularProgramData[keyForData].length;
      // handleEditExercise(mainExerciseObject, editIndex);
    }
    setSearchQueryCheck(false);
    dispatch(SET_SEARCH_INPUT_VALUE(""));
    setSearchActiveStatus(false);
  };

  return (
    <div className="header">
      {/* <div className="header_top" style={{ marginTop: !showFilterVersionButtons && type !== "CreateDraft" && "1rem" }}> */}
      <div className="header_top">
        <div
          className={
            type === "CreateDraft" && width < 768
              ? "header_left_for_draft"
              : "header_left"
          }
        >
          {!showFilterVersionButtons && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {backIcon && (
                  <div
                    className={
                      type === "CreateDraft"
                        ? "body__right__without_shadow"
                        : "body__right"
                    }
                    onClick={(e) => redirectToBack(e)}
                  >
                    <ChevronLeftIcon className="img_icon_list" />
                  </div>
                )}
                <span
                  className={
                    largeTitle === true
                      ? "title_text_large"
                      : title?.length > 20
                      ? "title_text_small"
                      : "title_text"
                  }
                >{`${title}`}</span>
              </div>

              <div className="header__input">
                {type === "CreateDraft" ? (
                  <input
                    value={tempNameForProgram}
                    onChange={(e) => {
                      setTempNameForProgram(e.target.value);
                    }}
                    type="text"
                    placeholder={placeholder}
                  />
                ) : (
                  <>
                    <SearchIcon className="header__searchIcon" />
                    <input
                      disabled={tempExe ? false : true}
                      value={query}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      id="search-box-input"
                      name="search-box-input"
                      type="text"
                      placeholder={placeholder}
                      ref={inputEl}
                      autoFocus={AddTips && tempExe.length === 0 ? true : false}
                      // autoFocus={searchActiveStatus === true ? true : false}
                      autocomplete="off"
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {showFilterVersionButtons && width > 768 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {backIcon && (
                  <div
                    className={
                      type === "CreateDraft"
                        ? "body__right__without_shadow"
                        : "body__right"
                    }
                    onClick={(e) => redirectToBack(e)}
                  >
                    <ChevronLeftIcon className="img_icon_list" />
                  </div>
                )}
                <span
                  className={
                    largeTitle === true
                      ? "title_text_large"
                      : title?.length > 20
                      ? "title_text_small"
                      : "title_text"
                  }
                >{`${title}`}</span>
              </div>

              <div className="header__input">
                {type === "CreateDraft" ? (
                  <input
                    value={tempNameForProgram}
                    onChange={(e) => {
                      setTempNameForProgram(e.target.value);
                    }}
                    type="text"
                    placeholder={placeholder}
                  />
                ) : (
                  <>
                    <SearchIcon className="header__searchIcon" />
                    <input
                      disabled={tempExe ? false : true}
                      value={query}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      id="search-box-input"
                      name="search-box-input"
                      type="text"
                      placeholder={placeholder}
                      ref={inputEl}
                      autoFocus={AddTips && tempExe.length === 0 ? true : false}
                      // autoFocus={searchActiveStatus === true ? true : false}
                      autocomplete="off"
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {showFilterVersionButtons && width < 768 && (
            <>
              {backIcon && (
                <div
                  className={
                    type === "CreateDraft"
                      ? "body__right__without_shadow"
                      : "body__right"
                  }
                  onClick={(e) => redirectToBack(e)}
                >
                  <ChevronLeftIcon className="img_icon_list" />
                </div>
              )}
              <span
                className={
                  largeTitle === true ? "title_text_large" : "title_text"
                }
              >{`${title}`}</span>
            </>
          )}
        </div>
        <div
          className={
            showFilterVersionButtons ? "button_section" : "button_section_right"
          }
        >
          <div
            className={
              showFilterVersionButtons
                ? "header_buttons"
                : "header_buttons_set_right"
            }
          >
            {actionButton ? (
              <Button
                variant="contained"
                className="action_button"
                onMouseOver={handleHoverActionSummary}
                onMouseOut={handleHoverOutActionSummary}
                onClick={() => {
                  dispatch(SHOW_ACTION_BUTTON(false));
                  if (visits && visits.length > 1) {
                    dispatch(SET_SCROLL(true));
                  }
                  history.push(
                    "/clients/action_summary?selectForm=OptimumTraining"
                  );
                }}
              >
                {addActionSummaryHover === true ? (
                  <ImArrowLeft size={27} color="#0074a5" />
                ) : (
                  <ImArrowLeft size={27} />
                )}
              </Button>
            ) : (
              ""
            )}
            {createProgramButton && (
              <>
                <Button
                  variant="contained"
                  style={{
                    backgroundImage:
                      "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                  }}
                  onMouseOver={handleHoverCreateProgram}
                  onMouseOut={handleHoverOutCreateProgram}
                  onClick={createPrograms}
                >
                  {addProgramHover === true ? (
                    <CreateProgramHover size={30} />
                  ) : (
                    <CreateProgram size={30} />
                  )}
                </Button>
              </>
            )}

            {programListButton && (
              <div className="programWithArchive">
                <Button
                  variant="contained"
                  onMouseOver={handleHoverProgramList}
                  onMouseOut={handleHoverOutProgramList}
                  //style={{ backgroundColor:"white" }}
                  style={{
                    backgroundImage:
                      "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                  }}
                  onClick={handleRedirect}
                >
                  {/* {
                  programListHover === true ?
                  <img className="img_icon" src={ProgramListIconHover}/>
                  :
                  <img className="img_icon" src={ProgramListIcon}/>
                } */}
                  {programListHover === true ? (
                    <ProgramListIconHover size={30} />
                  ) : (
                    <ProgramListIcon size={30} />
                  )}
                </Button>
                <Button
                  variant="contained"
                  // onMouseOver={handleHoverProgramList}
                  // onMouseOut={handleHoverOutProgramList}
                  style={{
                    backgroundImage:
                      "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                    padding: "12px 0px",
                  }}
                  onClick={() => {
                    if (currentRoute === "clients") {
                      dispatch(SET_CLIENT_DATA([]));
                      history.push("/archiveClients");
                    } else {
                      dispatch(SET_CLIENT_DATA([]));
                      history.push("/clients");
                    }
                  }}
                >
                  {currentRoute === "clients" ? (
                    <VscArchive size={20} />
                  ) : (
                    <IoMdArrowRoundBack size={20} />
                  )}
                </Button>
              </div>
            )}
            {/* {createExerciseButton && searchVal.length > 0 && ( */}
            {createExerciseButton && (
              <>
                <Button
                  variant="contained"
                  onMouseOver={handleHoverCreateExe}
                  onMouseOut={handleHoverOutCreateExe}
                  onClick={createExercise}
                  style={{
                    backgroundImage:
                      "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                  }}
                >
                  {createExeHover === true ? (
                    <AddExeHover size={28} />
                  ) : (
                    <AddExe size={28} />
                  )}
                </Button>

                {/* <Button variant="contained" onClick={createExercise}>
                          {t("Create Exercise")}
                          <img className="img_icon_header" src="https://img.icons8.com/material-sharp/64/ffffff/plus-2-math--v1.png" />
                        </Button> */}

                <Button
                  variant="contained"
                  onMouseOver={handleHoverListOfExe}
                  onMouseOut={handleHoverOutListOfExe}
                  onClick={sortExercises}
                  style={{
                    backgroundImage:
                      "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                  }}
                >
                  {listOfExeHover === true ? (
                    <ListOfExeHover size={28} />
                  ) : (
                    <ListOfExe size={28} />
                  )}
                </Button>
                {/* <Button variant="contained" onClick={sortExercises}>
                  {sort === 1 ? t("Descending") : t("Ascending")}
                  {sort === 1 ? (
                    <>
                      <ArrowDownwardIcon />
                      <img className="img_icon_header" src="https://img.icons8.com/windows/48/ffffff/generic-sorting.png" />
                    </>
                  ) : (
                    <>
                      <ArrowUpwardIcon />
                      <img className="img_icon_header" src="https://img.icons8.com/windows/32/ffffff/sort-amount-up.png" />
                    </>
                  )}
                </Button> */}
              </>
            )}

            {/* {createExerciseButton && query.length > 0 && (
              <>
                <Button variant="contained" onClick={createExercise}>

                  <img className="img_icon" src="https://img.icons8.com/material-sharp/64/ffffff/plus-2-math--v1.png"/>
                </Button>
                <Button variant="contained" onClick={sortExercises}>
                  {sort === 1 ? (
                    <>
                      <img className="img_icon" src="https://img.icons8.com/windows/48/ffffff/generic-sorting.png"/>
                    </>
                  ) : (
                    <>
                      <img className="img_icon" src="https://img.icons8.com/windows/32/ffffff/sort-amount-up.png"/>
                    </>
                  )}
                </Button>
              </>
            )} */}
            {AddTips && (
              <Button
                variant="contained"
                onMouseOver={handleHoverTips}
                onMouseOut={handleHoverOutTips}
                onClick={AddTips}
                style={{
                  backgroundImage:
                    "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                }}
                //onClick={AddTips} style={{ backgroundColor: 'white' }}
              >
                {tipsHover === true ? (
                  <TipsHover size={28} />
                ) : (
                  <Tips size={28} />
                )}
              </Button>
            )}
            {CreateDraft && (
              <Button
                variant="contained"
                onMouseOver={handleHoverForwardExe}
                onMouseOut={handleHoverOutForwardExe}
                onClick={CreateDraft}
                style={{
                  backgroundImage:
                    "linear-gradient(	#B0B0B0,whitesmoke,#B0B0B0)",
                }}
              >
                {forwardExeHover === true ? (
                  <ForwardExeHover size={28} />
                ) : (
                  <ForwardExc size={28} />
                )}
              </Button>
            )}
            {sendPdfToUser && (
              <Button
                variant="contained"
                onClick={() => {
                  sendPdfToUser("sendPdf");
                }}
              >
                {t("Send Program")}
              </Button>
            )}
            {sendPdfToUser && (
              <Button
                variant="contained"
                onClick={() => {
                  sendPdfToUser("downloadPdf");
                }}
              >
                {t("Pdf Preview")}
              </Button>
            )}
          </div>

          {showFilterVersionButtons && (
            <>
              <div className="versionFilter__buttons">
                {versionButtons.map((version, index) => {
                  return (
                    <>
                      <Button
                        key={index}
                        variant="contained"
                        className={`version__button ${
                          currentVersionIndex === index &&
                          "version__button__active"
                        }`}
                        onClick={() => filterByVersionFn(index, version.name)}
                      >
                        {t(version.name)}
                      </Button>
                    </>
                  );
                })}
              </div>
              {width < 769 && (
                <div className="header__input">
                  {type === "CreateDraft" ? (
                    <input
                      value={tempNameForProgram}
                      onChange={(e) => {
                        setTempNameForProgram(e.target.value);
                      }}
                      type="text"
                      placeholder={placeholder}
                    />
                  ) : (
                    <>
                      <SearchIcon className="header__searchIcon" />
                      <input
                        disabled={tempExe ? false : true}
                        value={query}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          handleKeyDown(e);
                        }}
                        type="text"
                        placeholder={placeholder}
                        ref={inputEl}
                        autoFocus={
                          AddTips && tempExe.length === 0 ? true : false
                        }
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {tipsHeader === "tipsHeader" ? null : (
        <div
          className="header__bottom"
          // style={{ display: type === "CreateDraft" ? "" : "none" }} serchbox Hide in header
        >
          {/* <div className="header__input">
            {type === "CreateDraft" ? (
              <input value={tempNameForProgram} onChange={(e) => { setTempNameForProgram(e.target.value) }} type="text" placeholder={placeholder} />
            ) : (
              <>
                <SearchIcon className="header__searchIcon" />
                <input
                  value={query}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  type="text"
                  placeholder={placeholder}
                  ref={inputEl}
                  autoFocus={AddTips && tempExe.length === 0 ? true : false}
                />
              </>
            )}
          </div> */}
        </div>
      )}
      {/* {showFilterVersionButtons && (
        <div className="versionFilter__buttons">
          {versionButtons.map((version, index) => {
            return (
              <Button
                key={index}
                variant="contained"
                className={`version__button ${currentVersionIndex === index && "version__button__active"
                  }`}
                onClick={() => filterByVersionFn(index, version.name)}
              >
                {version.name}
              </Button>
            );
          })}
        </div>
      )} */}
    </div>
  );
}

export default AppHeader;
