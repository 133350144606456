import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { SET_AUTH } from "./redux/actions";

const OptimumProRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  const CheckSubscribed =
    auth instanceof Object && auth.user.subscriptionExpired;

  const isAuthenticated = () => {
    if (auth) {
      const decodedToken = jwt_decode(auth.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        dispatch(SET_AUTH(""));
        return false;
      }
      return true;
    }
  };

  if (CheckSubscribed === true) {
    return <Redirect to="/updatestripe" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated() &&
          userData.isOptimumPro !== undefined &&
          userData.isOptimumPro ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
};

export default OptimumProRoute;
