import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import './Loaders.scss'
import ContentLoader from "react-content-loader";

export const suspenseFallbackLoader = ()=>{
  return (
      <div className='app__loader'>
          <BeatLoader color={"#36D7B7"} loading={true} size={22}/>
      </div>
  )
};

export const ListLoader = () => (
    <ContentLoader
        speed={1}
        viewBox="0 0 380 45"

    >
        <rect x="4" y="2" rx="4" ry="4" width="370" height="12" />
        <rect x="4" y="20" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
);

export const ClientLoader = () => (
    <ContentLoader
        speed={1}
        viewBox="0 0 380 50"
    >
        <circle  cx="20" cy="23" r="15" />
        <rect x="40" y="10" rx="4" ry="4" width="320" height="10" />
        <rect x="40" y="26" rx="3" ry="3" width="250" height="8" />
    </ContentLoader>
) ;
