// import { toast } from "react-toastify";
import moment from "moment";
import toast from "react-hot-toast";

// export const successToaster = (message) => {
//   return toast.success(message, {
//     position: toast.POSITION.TOP_RIGHT,
//   });
// };

export const successToaster = (message) => {
  return toast.success(message);
};

// export const errorToaster = (message) => {
//   return toast.error(message, {
//     position: toast.POSITION.TOP_RIGHT,
//   });
// };

export const errorToaster = (message) => {
  return toast.error(message);
};

export const validateEmailFn = (email, setEmailError) => {
  let emailError = false;
  if (!email) {
    emailError = true;
  } else {
    if (
      !email.match(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
    ) {
      emailError = true;
    }
  }
  setEmailError(emailError);
  if (emailError) {
    return false;
  }
  return true;
};

export const validateEmailPasswordFn = (
  email,
  setEmailError,
  password,
  setPasswordError
) => {
  let emailError = false;
  let passwordError = false;
  if (!email) {
    emailError = true;
  } else {
    if (
      !email.match(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
    ) {
      emailError = true;
    }
  }
  if (!password) {
    passwordError = true;
  }

  setEmailError(emailError);
  setPasswordError(passwordError);

  if (emailError || passwordError) {
    return false;
  }
  return true;
};

export const validateResetPassword = (
  password,
  setPasswordError,
  confirmPassword,
  setConfirmPasswordError
) => {
  let passwordError = false;
  let confirmPasswordError = false;

  if (!password) {
    passwordError = true;
  }

  if (!confirmPassword) {
    confirmPasswordError = true;
  } else if (password !== confirmPassword) {
    confirmPasswordError = true;
  } else {
    confirmPasswordError = false;
  }

  setPasswordError(passwordError);
  setConfirmPasswordError(confirmPasswordError);

  if (passwordError || confirmPasswordError) {
    return false;
  }
  return true;
};

export const payerValidationFn = (payer, setStatus) => {
  let payerError = false;
  if (payer.length <= 0) {
    payerError = "Payer is required";
  } else {
    if (!payer.startsWith("46")) {
      payerError = "Payer is should start with 46";
    }
    //  else if (payer.length < 8) {
    //   payerError = "Payer number is not valid";
    // }
  }
  // setpayerError(payerError);
  setStatus(payerError);
  if (payerError) {
    return false;
  }
  return true;
};

export const getDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const handleSupersetForAllVersion = (
  mainexercise,
  AllProgramExercise,
  TotalLength,
  index,
  parentKey,
  childKey,
  key
) => {
  if (mainexercise[parentKey] === false && mainexercise[childKey] === false) {
    if (index === 0) {
      mainexercise[parentKey] = true;
      if (index < TotalLength - 1) {
        if (AllProgramExercise[key][index + 1][parentKey] === true) {
          AllProgramExercise[key][index + 1][parentKey] = false;
          AllProgramExercise[key][index + 1][childKey] = true;
        }
      }
    } else {
      if (AllProgramExercise[key][index - 1][parentKey] === true) {
        mainexercise[childKey] = true;
      } else {
        if (AllProgramExercise[key][index - 1][childKey] === true) {
          mainexercise[childKey] = true;
        } else {
          if (index < TotalLength - 1) {
            mainexercise[parentKey] = true;
            repeatGFkfalse(
              AllProgramExercise,
              index,
              TotalLength,
              parentKey,
              childKey,
              key
            );
          } else {
            if (AllProgramExercise[key][index - 1][parentKey] === true) {
              mainexercise[childKey] = true;
            } else {
              mainexercise[parentKey] = true;
            }
          }
        }
      }
    }
  } else if (mainexercise[parentKey] === true) {
    mainexercise[parentKey] = false;
    handleRepeteGfKey(
      AllProgramExercise,
      index,
      TotalLength,
      parentKey,
      childKey,
      key
    );
  } else if (mainexercise[childKey] === true) {
    if (index === 0) {
      mainexercise[parentKey] = true;
      mainexercise[childKey] = false;
      repeatGFkfalse(
        AllProgramExercise,
        index,
        TotalLength,
        parentKey,
        childKey,
        key
      );
    } else {
      if (AllProgramExercise[key][index - 1][parentKey] === true) {
        mainexercise[childKey] = false;
        // code peast idhar karo
        handleRepeteGfKey(
          AllProgramExercise,
          index,
          TotalLength,
          parentKey,
          childKey,
          key
        );
      } else {
        if (AllProgramExercise[key][index - 1][childKey] === true) {
          mainexercise[parentKey] = true;
          mainexercise[childKey] = false;
          repeatGFkfalse(
            AllProgramExercise,
            index,
            TotalLength,
            parentKey,
            childKey,
            key
          );
        }
      }
    }
  }
  AllProgramExercise[key][index] = mainexercise;

  return AllProgramExercise;
};

const repeatGFkfalse = (
  AllProgramExercise,
  index,
  TotalLength,
  parentKey,
  childKey,
  key
) => {
  if (index < TotalLength - 1) {
    console.log("repeatGFkfalse")
    if (AllProgramExercise[key][index + 1][parentKey] === true) {
      AllProgramExercise[key][index + 1][parentKey] = false;
      AllProgramExercise[key][index + 1][childKey] = true;
    }
  }
  return AllProgramExercise;
};

const handleRepeteGfKey = (
  AllProgramExercise,
  index,
  TotalLength,
  parentKey,
  childKey,
  key
) => {
  if (index < TotalLength - 1) {
    console.log("handleRepeteGfKey")
    if (AllProgramExercise[key][index + 1][childKey] === true) {
      AllProgramExercise[key][index + 1][parentKey] = true;
      AllProgramExercise[key][index + 1][childKey] = false;
      if (index < TotalLength - 2) {
        if (AllProgramExercise[key][index + 2][parentKey] === true) {
          AllProgramExercise[key][index + 2][parentKey] = false;
          AllProgramExercise[key][index + 2][childKey] = true;
        }
      }
    }
  }

  return AllProgramExercise;
};

export const handleRepsAndSets = (exerciseArray, event, mainIndex, key) => {
  exerciseArray.map((data, index) => {
    if (mainIndex === index) {
      if (key === "sets") {
        data.numOfSets = event.target.value;
      }
      if (key === "reps") {
        data.numOfReps = event.target.value;
      }
      if (key === "unit") {
        data.unit = event.target.value;
      }
    }
  });

  return exerciseArray;
};

export const handleMoveExerciseForAll = (
  exerciseArray,
  movement,
  currentExerciseIndex,
  previousExerciseIndex,
  nextExerciseIndex,
  event
) => {
  const previousExercise = exerciseArray[previousExerciseIndex];
  const nextExercise = exerciseArray[nextExerciseIndex];
  if (movement === "moveUp" || event.keyCode === 38) {
    if (exerciseArray[nextExerciseIndex].GroupedKey === true && exerciseArray[currentExerciseIndex].groupFirstKey === true) {
      exerciseArray[previousExerciseIndex].GroupedKey = true;
    }
    if (exerciseArray[currentExerciseIndex].groupFirstKey === false && exerciseArray[previousExerciseIndex].GroupedKey === false){
      exerciseArray[currentExerciseIndex].GroupedKey = false;

    }
  } else {
    if (exerciseArray[previousExerciseIndex].GroupedKey === false) {
      exerciseArray[nextExerciseIndex].GroupedKey = false;
    }
    if(exerciseArray[currentExerciseIndex].GroupedKey === true){
      exerciseArray[nextExerciseIndex].GroupedKey = true;
    }
  }
  exerciseArray[
    movement === "moveUp" || event.keyCode === 38
      ? previousExerciseIndex
      : nextExerciseIndex
  ] = exerciseArray[currentExerciseIndex];
  exerciseArray[currentExerciseIndex] =
    movement === "moveUp" || event.keyCode === 38
      ? previousExercise
      : nextExercise;
  return exerciseArray;
};

export const handleSupersetForDeleteExercise = (
  mainExerciseList,
  fullprogram,
  TotalLength,
  index,
  parentKey,
  childKey,
  key
) => {
  if (TotalLength > 0) {
    const checkedGroupedKey =
      mainExerciseList &&
      mainExerciseList.filter((exe) => {
        return exe[childKey] === true;
      });

    if (checkedGroupedKey.length === mainExerciseList.length) {
      mainExerciseList[0][parentKey] = true;
      mainExerciseList[0][childKey] = false;
    } else {
      if (TotalLength > 1) {
        if (mainExerciseList[0][childKey] === true) {
          mainExerciseList[0][parentKey] = true;
          mainExerciseList[0][childKey] = false;
          repeatGFkfalse(fullprogram, 0, TotalLength, parentKey, childKey, key);
        } else {
          if (mainExerciseList[index][parentKey] === true) {
            if (index > 0) {
              if (fullprogram[key][index - 1][parentKey] === true) {
                mainExerciseList[index][parentKey] = false;
                mainExerciseList[index][childKey] = true;
              }
            }
          } else if (mainExerciseList[index][childKey] === true) {
            if (index > 0) {
              if (
                fullprogram[key][index - 1][parentKey] === false &&
                fullprogram[key][index - 1][childKey] === false
              ) {
                mainExerciseList[index][parentKey] = true;
                mainExerciseList[index][childKey] = false;
              }
            }
          }
        }
      }
    }
  }
  fullprogram[key] = mainExerciseList;
  return fullprogram;
};
